import icon from '../../assets/placeholder.svg'
import image from '../../assets/placeholder.jpg'

const placeholderImage = {
  isPlaceholder: true,
  objectFit: 'none',
  medium: {
    src: image,
    maxWidth: 500,
    aspectRatio: 1,
    sizes: '',
    srcSet: ''
  }
}

export default {
  kicker: {
    text: 'KICKER TEXT'
  },
  title: {
    text: 'Title Text'
  },
  text_1: {
    text: 'First placeholder for description text.'
  },
  text_2: {
    text: 'Second placeholder for description text.'
  },
  images: [
    {
      src: placeholderImage
    }
  ],
  collection: [
    {
      kicker: {
        text: 'Item Kicker'
      },
      title: {
        text: 'Title Text'
      },
      description: 'Lorem ipsum dolor sit amet, dopi se contetur adicing elit.',
      icon: {
        src: icon,
        color: '#667eea',
        bg: 'transparent'
      }
    },
    {
      kicker: {
        text: 'Item Kicker'
      },
      title: {
        text: 'Title Text'
      },
      description: 'Lorem ipsum dolor sit amet, dopi se contetur adicing elit.',
      icon: {
        src: icon,
        color: '#667eea',
        bg: 'transparent'
      }
    },
    {
      kicker: {
        text: 'Item Kicker'
      },
      title: {
        text: 'Title Text'
      },
      description: 'Lorem ipsum dolor sit amet, dopi se contetur adicing elit.',
      icon: {
        src: icon,
        color: '#667eea',
        bg: 'transparent'
      }
    }
  ],
  buttons: [
    {
      text: 'Button One',
      link: '/'
    },
    {
      text: 'Button Two',
      link: '/'
    }
  ]
}
